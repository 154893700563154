<template>
  <card class="w-full mx-2 md:ml-4 user-form-data">
    <template #content>
      <card class="inner-card">
        <template #title>
          <div class="flex justify-content-between">
            Datos Bancarios
            <Button
              icon="pi pi-plus-circle"
              iconPos="left"
              label="Agregar Cuenta"
              style="width: 200px"
              @click="addAccount"
              v-if="!['taquilla'].includes($store.getters['auth/role'])"
            />
          </div>
          <hr />
        </template>
        <template #content>
          <div class="header_intro ml-1 lg:ml-2">
            <h6>Consejos</h6>
            <ul>
              <li>
                Las cuentas bancarias agregadas se mostrará al comprador como
                múltiples opciones para la transacción de créditos.
              </li>
              <li>
                Asegúrate de que la cédula de identidad del propietario de la
                cuenta sea igual a la cédula verificada en Lotomax.
              </li>
              <li>Puedes agregar hasta 10 cuentas bancarias.</li>
            </ul>
          </div>
          <div class="accounts-list">
            <card
              v-for="(item, index) in userBankAccounts"
              :key="index"
              class="account-item mb-4"
            >
              <template #title>
                <div class="title-name flex justify-content-between">
                  <span class="account-title flex-shrink-1">{{
                    item.fk_payment_method
                  }}</span>
                  <div style="min-width: fit-content">
                    <Button
                      icon="pi pi-times"
                      label="Eliminar"
                      class="p-button-outlined ml-2"
                      @click="confirmDelete(item)"
                      v-if="!['taquilla'].includes($store.getters['auth/role'])"
                    />
                  </div>
                </div>
              </template>
              <template #content>
                <div class="p-fluid grid table-title">
                  <div
                    v-for="field in item.fk_payment_method_structure"
                    :key="field.key"
                    class="col"
                  >
                    <span>{{ field.label }}</span>
                  </div>
                </div>
                <div class="p-fluid grid table-detail">
                  <div
                    v-for="field in item.fk_payment_method_structure"
                    :key="field.key"
                    class="col"
                  >
                    <span>{{ item.bank_data[field.key] }}</span>
                  </div>
                </div>
              </template>
            </card>
          </div>
        </template>
      </card>
    </template>
  </card>
  <Dialog
    v-if="edit_account"
    v-model:visible="edit_account"
    :modal="true"
    :showHeader="true"
    :breakpoints="{ '960px': '75vw' }"
    :style="{ width: '50vw', minHeight: '300px' }"
    contentClass="modal"
    :closable="false"
    class="modal-account"
  >
    <card class="inner-card">
      <template #title>
        <div class="flex justify-content-between align-items-center">
          <span>{{ modal_title }}</span>
          <Button
            icon="pi pi-times"
            class="p-button-rounded p-button-danger p-button-text p-0 m-0 close-modal-button"
            @click="edit_account = false"
          />
        </div>
        <hr />
      </template>
      <template #content>
        <div
          v-if="!account.id && !account.fk_payment_method"
          class="flex flex-wrap justify-content-start"
        >
          <div
            v-for="(item, index) in PaymentMethods"
            :key="index"
            class="payment-method-button mb-3 ml-3"
            :style="`border-left-color: ${getRandomColor()}`"
            @click="showAccountInputForm(item.id)"
          >
            {{ item.name }}
          </div>
        </div>
        <div v-else>
          <div
            v-for="field in selected_payment_method.fk_payment_method_structure"
            :key="field.key"
            class="field grid"
          >
            <label
              for="field.key"
              class="col-12 mb-2 lg:col-4 lg:mb-0 lg:justify-content-end"
              >{{ field.label }}</label
            >
            <div class="col-12 lg:col-8 flex flex-wrap">
              <InputText
                :id="'input_' + field.key"
                type="text"
                v-model="account[field.key]"
                autocomplete="off"
                :disabled="processing_data"
                :class="{
                  'p-invalid':
                    v$.account[field.key] &&
                    v$.account[field.key].$invalid &&
                    submitted,
                }"
              />
              <small
                v-if="
                  v$.account[field.key] &&
                  ((v$.account[field.key].$invalid && submitted) ||
                    v$.account[field.key].$pending.$response)
                "
                class="p-error"
                >Campo rquerido.</small
              >
            </div>
          </div>
        </div>
      </template>
      <template #footer v-if="account.fk_payment_method">
        <div class="flex justify-content-end">
          <Button
            label="Cancelar"
            icon="pi pi-times-circle"
            @click="edit_account = false"
            class="mr-3"
            :disabled="processing_data"
          />
          <Button
            label="Guardar"
            icon="pi pi-check-circle"
            @click="saveAccountData"
            :disabled="processing_data"
            :loading="processing_data"
          />
        </div>
      </template>
    </card>
  </Dialog>
  <Dialog
    v-model:visible="delete_account"
    :style="{ width: '450px' }"
    header="Confirmar Eliminación"
    :modal="true"
  >
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <span v-if="account">
        ¿Estás seguro(a) de que quieres eliminar este Método de Pago?
      </span>
    </div>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="delete_account = false"
        :disabled="processing_data"
      />
      <Button
        label="Si"
        icon="pi pi-check"
        class="p-button-text"
        @click="deleteAccount"
        :disabled="processing_data"
        :loading="processing_data"
      />
    </template>
  </Dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {},
  data() {
    return {
      edit_account: false,
      account: {},
      selected_payment_method: null,
      processing_data: false,
      submitted: false,
      delete_account: false,
    };
  },
  created() {
    this.getUserBankAccounts();
    this.getPaymentMethods({ client: true });
  },
  methods: {
    ...mapActions("users", [
      "getUserBankAccounts",
      "registerBankAccount",
      "updateBankAccount",
      "deleteBankAccount",
    ]),
    ...mapActions("configurations", ["getPaymentMethods"]),
    addAccount() {
      this.submitted = false;
      this.account = {};
      this.modal_title = "Selecciona un Método de Pago";
      this.edit_account = true;
    },
    showAccountInputForm(id) {
      this.account.fk_payment_method = id;
      this.selected_payment_method = this.PaymentMethods.find(
        (e) => e.id === id
      );
      this.modal_title = "Registrar " + this.selected_payment_method.name;
      this.setInputFocus(
        `input_${this.selected_payment_method.fk_payment_method_structure[0].key}`
      );
    },
    saveAccountData() {
      this.submitted = true;
      if (this.v$.$invalid) return;

      let data = {
        fk_payment_method: this.account.fk_payment_method,
        bank_data: {},
      };
      if (this.account.id) data.id = this.account.id;
      this.selected_payment_method.fk_payment_method_structure.forEach((e) => {
        data.bank_data[e.key] = this.account[e.key];
      });
      this.processing_data = true;
      var action = !data.id ? this.registerBankAccount : this.updateBankAccount;
      action(data)
        .then((resp) => {
          if (resp.data.status == "SUCCESS") {
            this.$store.dispatch("changeSystemMessage", {
              severity: "success",
              detail: "Registrado con Éxito.",
              life: 4000,
            });
            this.getUserBankAccounts();
            this.edit_account = false;
          }
        })
        .catch((err) => {
          if (err.response) {
            this.$store.dispatch("changeSystemMessage", {
              severity: "error",
              detail: "Fallo al Registrar.",
              life: 6000,
            });
          }
        })
        .finally(() => (this.processing_data = false));
    },
    confirmDelete(item) {
      this.account = item;
      this.delete_account = true;
    },
    deleteAccount() {
      if (this.account && this.account.id)
        this.deleteBankAccount(this.account.id)
          .then((resp) => {
            if (resp.data.status == "SUCCESS") {
              this.$store.dispatch("changeSystemMessage", {
                severity: "success",
                detail: "Registro Eliminado.",
                life: 4000,
              });
              this.getUserBankAccounts();
            }
          })
          .catch((err) => {
            if (err.response) {
              this.$store.dispatch("changeSystemMessage", {
                severity: "error",
                detail: "Fallo al Eliminar.",
                life: 6000,
              });
            }
          })
          .finally(() => {
            this.processing_data = false;
            this.delete_account = false;
            this.account = {};
          });
    },
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    setInputFocus(input_id) {
      setTimeout(() => {
        let input = document.getElementById(input_id);
        if (input) input.focus();
      }, 100);
    },
  },
  computed: {
    ...mapGetters("users", ["userBankAccounts"]),
    ...mapGetters("configurations", ["PaymentMethods"]),
  },
  validations() {
    let val = {
      account: {},
    };
    if (this.edit_account && this.selected_payment_method)
      this.selected_payment_method.fk_payment_method_structure.forEach((e) => {
        val.account[e.key] = { required };
      });
    return val;
  },
};
</script>
<style lang="scss" scoped>
.user-form-data :deep(.inner-card) {
  border: 0.5px solid rgba(0, 0, 0, 0.38);
  border-radius: 10px;
}
.account-item :deep(.p-card-body) {
  padding: 0;
}
.account-item :deep(.p-card-title) {
  margin-bottom: 0;
}
.account-item :deep(.p-card-content) {
  padding-top: 0;
}
.account-item {
  border: 0.1px solid #b1acac;
}
.account-item :deep(.title-name) {
  background-color: #eaeaea;
  padding: 5px;
  border-bottom: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.header_intro {
  h6 {
    font-weight: 700;
  }
  ul > li {
    font-family: Roboto;
    font-weight: 400;
    color: #7d7d7d;
    margin-bottom: 5px;
  }
}
.account-title {
  padding: 10px;
  font-weight: 600;
  font-size: 12px;
}
.title-name :deep(.p-button) {
  color: #323232;
  padding: 8px;
  min-width: 80px;
}
.table-title {
  margin: 5px;
  span {
    font-size: 11px;
    color: #7d7d7d;
    font-weight: 600;
  }
}
.table-detail {
  margin: 5px;
  span {
    font-size: 12px;
    color: #141010;
    font-weight: 400;
  }
}
.close-modal-button {
  margin-top: -8px !important;
}
</style>
<style>
.modal-account.p-dialog .p-dialog-header {
  padding: 0.5rem !important;
}
.p-dialog {
  background: #ffffff;
  border-radius: 10px !important;
}
.modal {
  background: #ffffff;
  border-radius: 10px !important;
}
.modal .inner-card {
  border: 0.5px solid rgba(0, 0, 0, 0.38);
  border-radius: 10px;
  height: 100%;
}
.modal .p-card-content {
  min-height: 300px;
}
.payment-method-button {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #323232;
  border-left: 4px solid;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  min-width: 150px;
  padding-left: 20px;
  padding-right: 10px;
  cursor: pointer;
}
.payment-method-button:hover {
  background: #e6e6e6;
}
.field > label {
  font-weight: 500;
}
.field input[type="text"] {
  min-width: 200px;
  width: 90%;
}
.modal-account .p-card-title {
  position: relative;
  padding: 1rem 1rem 0rem 1rem;
}
.modal-account .inner-card .p-card-body {
  padding: 0;
}
.modal-account .inner-card .p-card-content {
  padding: 1rem;
}
.modal-account .inner-card .p-card-footer {
  padding: 1rem;
  background: #e6e6e6;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>

<template>
  <card class="inner-card password">
    <template #title>Cambiar la Contraseña</template>
    <template #content
      >
      <hr />
      <div class="flex flex-column align-items-center justify-content-center">
        <div class="inline-flex">
          <label>Contraseña Actual </label> <label class="red ml-1"> * </label>
          <div class="p-float-label ml-2">
            <InputText
              type="password"
              id="password1"
              autocomplete="off"
              class="label"
              v-model="current_password"
            />
            <button
              class="btn btn-primary ml-1 pi pi-eye -ml-5"
              type="button"
              @click="showPassword(1)"
            ></button>
          </div>
        </div>
        <br />
        <br />
        <div class="inline-flex">
          <label>Nueva Contraseña</label> <label class="red ml-1"> * </label>
          <div class="p-float-label ml-2">
            <InputText
              type="password"
              id="password2"
              autocomplete="off"
              class="label"
              v-model="new_password"
            />
            <button
              class="btn btn-primary ml-1 pi pi-eye -ml-5"
              type="button"
              @click="showPassword(2)"
              name="clave1"
            ></button>
          </div>
        </div>
        <div>
					<VerificationCodeModal
						class="modal" 
						v-if="this.showVerificationModal" 
						@cancel="showVerificationModal = false"/>
				</div>
        <br />
        <br />
        <div class="inline-flex">
          <label>Nueva Contraseña</label> <label class="red ml-1"> * </label>
          <div class="p-float-label ml-2">
            <InputText
              type="password"
              id="password3"
              autocomplete="off"
              class="label"
              v-model="confirmed_password"
            />
            <button
              class="btn btn-primary ml-1 pi pi-eye -ml-5"
              type="button"
              @click="showPassword(3)"
              name="clave2"
            ></button>
          </div>
        </div>
      </div>
      <hr />
      <div class="flex align-items-center justify-content-center">
        <button class="btn1 btn-primary ml-1" type="button" @click="confirmPassword()">
          Confirmar Contraseña
        </button>
      </div>
    </template>
  </card>
</template>
<script>
import VerificationCodeModal from './VerificationCodeModal.vue';

export default {
  components: {
    VerificationCodeModal
  },
  data() {
    return {
      condition: true,
      showVerificationModal: false,
      current_password: '',
      new_password: '',
      confirmed_password: '',
    };
  },
  mounted() {},
  beforeUnmount() {},
  created() {},
  methods: {
    confirmPassword(){
      this.showVerificationModal = !this.showVerificationModal;
		},
    showPassword(id) {
			let input = document.getElementById(`password${id}`);

      if (!input) return;
  
			if (input.type === "password") {
				input.type = "text";
			} else {
        input.type = "password";
      }
		},
  },

  computed: {},
  watch: {},
};
</script>
<style lang="scss" scoped>
.password {
  border: 0.5px solid rgba(0, 0, 0, 0.38);
  border-radius: 10px;
}

.btn {
  background-color: transparent;
  color: #424141;
  border: 0.5px solid #474444;
  border-radius: 5px;
  width: 20px;
  height: 10px;
  border-style: none;
}

.btn1 {
  width: 180px;
  height: 40px;
  background: #cc195a;
  border-radius: 0px;
  color: #ffffff;
  border-radius: 5px;
  border-color: #cc195a;
  font-family: Roboto;
  font-size: 15px;
  border-style: none;
}

.btn2 {
  background-color: transparent;
  color: #cc195a;
  border-radius: 5px;
  border-width: 1px;
  border-color: #cc195a;
}

.btn2:hover {
  cursor: pointer;
}

h8 {
  color: #424141;
}

.code {
  margin-right: 65px;
}
.label {
  height: 30px;
  background-color: #eaeaea;
  border-radius: 5px;
  border-color: transparent;
}

.red {
  color: #cc195a;
}

.btn:hover {
  cursor: pointer;
}

.btn1:hover {
  cursor: pointer;
}

@media screen and (max-width: 414px) {
  .dist {
    margin-left: 15px;
  }
  .dist2 {
    margin-left: 50px;
  }
}
</style>

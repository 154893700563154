<template>
  <card class="w-full mx-4 security">
    <template #content>
      <SecurityOptions
        v-if="
          !change_pasword 
        "
        @changePassword="changePassword"
      />
      <ChangePassword v-if="change_pasword" />
      <!-- <SecurityQuestions v-if="security_questions" /> -->
    </template>
  </card>
</template>

<script>
import SecurityOptions from "./SecurityOptions.vue";
import ChangePassword from "./ChangePassword.vue";
// import SecurityQuestions from "./SecurityQuestions.vue";

export default {
  components: {
    SecurityOptions,
    ChangePassword,
    // SecurityQuestions,
  },
  data() {
    return {
      change_pasword: false,
      // TODO
      //  Para el proximo MVP se agregará el modulo de las preguntas de seguridad 
      // security_questions: false,
    };
  },
  mounted() {},
  beforeUnmount() {},
  created() {},
  methods: {
    changePassword() {
      this.change_pasword = true;
    },
    // TODO
    //  Para el proximo MVP se agregará el modulo de las preguntas de seguridad 
    // securityQuestions() {
    //   this.security_questions = true;
    // },
  },
  computed: {},
  watch: {},
};
</script>
<style lang="scss" scoped>
.security {
  border-radius: 4px;
}

@media screen and (max-width: 820px) {
}
</style>

<!-- gap world steak cheap this negative april another lady dumb distance exhibit -->

<template>
  <div class="profile-avatar">
    <card class="card-avatar-profile">
      <template #header>
        <Avatar size="xlarge" shape="circle" icon="pi pi-user" />
      </template>
      <template #title>
        {{ userData.first_name + " " + userData.last_name }}
        <hr />
      </template>
      <template #content>
        <span class="text-400">{{ userData.fk_contact.email }}</span>
        <span class="text-400">{{
          "+" +
          userData.fk_contact.fk_phone_number_code +
          userData.fk_contact.phone_number
        }}</span>
      </template>
    </card>
  </div>
</template>
<script>
import Avatar from "primevue/avatar";
import { mapGetters } from "vuex";

export default {
  components: {
    Avatar
  },
  data() {
    return {};
  },
  mounted() {},
  beforeUnmount() {},
  created() {},
  methods: {},
  computed: {
    ...mapGetters("userprofile", ["userData"]),
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.profile-avatar {
  height: 200px;
  width: 208px;
  min-width: 208px;
  position: relative;
  margin-bottom: 20px;
}

.card-avatar-profile {
  height: 200px;
  max-height: 200px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.card-avatar-profile :deep(.p-card-header) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 15px;
}

.card-avatar-profile :deep(.p-card-title) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 1.1rem;
  hr {
    width: 100%;
  }
}

.card-avatar-profile :deep(.p-card-content) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 0px;
}

.card-avatar-profile :deep(.p-avatar) {
  background-color: #ffffff;
  border: solid 1px #cacaca;
}
</style>

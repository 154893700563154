<template>
    <div 
        class="p-dialog-mask p-component-overlay p-component-overlay-enter"
        style="z-index: 1100"
      >
        <div class="overflow-auto surface-overlay credit-modal">
      <card class="inner-card verification">
            <template #title>
              <div class="flex grid align-items-center justify-content-center">
                <div class="col-10">
                  Código de Verificación.
                </div>
                <div class="">
                  <Button
                    icon="pi pi-times"
                    class="cancel-buttom"
                    @click="$emit('cancel')"
                  />
                </div>
              </div>
              <hr>
            </template>
        <template #content>
                <div class="flex flex-column  align-items-center justify-content-center">
                  <h5>Para poder realizar el siguiente cambio, debe completar la siguiente verificación.</h5>
                  <h6>Por favor, introduce el código de 6 dígitos que le fue enviado al número {{ }} y al correo {{ }}. </h6>
            <div class="p-float-label inline-flex">
              <InputText type="text" autocomplete="off" class="label label1"/>
            </div>
            <h6>¿No has recibido el código?</h6>
            <h5 class="red" @click="sendNotification('mail')">Reenviar código de verificación.</h5>
          </div>
              </template>
          <template #footer>
          <div class="flex align-items-center justify-content-center">
            <button class="btn1 btn-primary" type="button" @click="$emit('cancel')">Aceptar</button>
          </div>
        </template>
      </card>
        </div>
      </div>
    </template>
    <script>
    export default {
      components: {},
      data() {
        return {
          condition: true,
        };
      },
      mounted() {},
      beforeUnmount() {},
      created() {},
      methods: {
        sendNotification(key) {
          this.notificationOptions[key].is_processing = true;
          this.$store
            .dispatch("ticketoffice/sendPlayOrderTicketNotification", {
              serial: this.currentPlayData.ticket.serial,
              method: key,
            })
            .then((resp) => {
              this.notificationOptions[key].already_sent = true;
              this.$store.dispatch("changeSystemMessage", {
                severity: "success",
                detail: resp.data.message,
                life: 4000,
              });
            })
            .catch(() => {
              this.$store.dispatch("changeSystemMessage", {
                severity: "error",
                summary: "Error",
                detail: "Notificación fallida.",
                life: 4000,
              });
            })
            .finally(() => {
              this.notificationOptions[key].is_processing = false;
            });
        },
      },
    };
    </script>
    <style lang="scss" scoped>
    .verification {
      border: 0.5px solid rgba(0, 0, 0, 0.38);
      border-radius: 10px;
    }
    
    
    .btn1 {
      width: 180px;
      height: 40px;
      background: #cc195a;
      border-radius: 0px;
      color: #ffffff;
      border-radius: 5px;
      border-color: #cc195a;
      font-family: Roboto;
      font-size: 15px;
      border-style: none;
    }
    
    .label {
      height: 35px;
      width: 250px;
      background-color: #eaeaea;
      border-radius: 5px;
      border-color: transparent;
    }
    
    .red {
      color: #cc195a;
    }
    
    .btn1:hover {
      cursor: pointer;
    }
    
    .red:hover{
      cursor: pointer
    }
    .inner-card :deep(.p-card-footer) {
      padding: 1rem;
      background: #e6e6e6;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .credit-modal {
      background-color: #ffff;
      border-radius: 10px;
      padding: 1rem;
      width: 720px;
      min-height: 500px;
      height: auto;
    }
    .cancel-buttom {
      background-color: transparent;
      color: #000000;
      font-size: 2rem;
    }
    .cancel-buttom:hover {
      background-color: transparent;
      color: #cc195a;
    }
    
    @media screen and (max-width: 820px) {
      .btn {
      }
    }
    </style>
    
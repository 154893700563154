<template>
  <div class="grid mt-8">
    <div class="col-12 flex justify-content-between">
      <menu-bar />
      <keep-alive>
        <component
          :key="selected_option"
          :is="getViewComponent(selected_option)"
        />
      </keep-alive>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import MenuBar from "./MenuBar.vue";
import Security from "../../components/profile/Security.vue";
import UserAvatar from "@/components/profile/UserAvatar.vue";
import UserFormData from "@/components/profile/UserFormData.vue";
import UserBanksAccountsVue from "@/components/profile/UserBanksAccounts.vue";

export default {
  components: {
    MenuBar,
    UserFormData,
    UserAvatar,
    Security,
    UserBanksAccountsVue,
  },
  data() {
    return {
      selected_option: "general",
    };
  },
  mounted() {
    document
      .getElementsByClassName("layout-main-container")[0]
      .classList.add("user-profile-view");
  },
  beforeUnmount() {
    document
      .getElementsByClassName("layout-main-container")[0]
      .classList.remove("user-profile-view");
  },
  created() {
    this.getUserProfile();
  },
  methods: {
    ...mapActions("userprofile", ["getUserProfile"]),
    getViewComponent(option) {
      if (option === "general") return "UserFormData";
      if (option === "seguridad") return "Security";
      else if (option === "bank") return "UserBanksAccountsVue";
    },
  },
  computed: {},
  watch: {},
};
</script>
<style>
@media only screen and (min-width: 770px) and (min-height:500px) {
  .layout-main-container.user-profile-view {
    margin-left: 200px !important;
  }
}
</style>

<template>
  <div></div>
  <card class="inner-card security">
    <template #title>Seguridad</template>
    <template #content
      >{{}}
      <hr />
      <br />
      <div class="flex flex-column">
        <div class="flex">
          <h6>Cambiar Contraseña</h6>
        </div>
        <div class="inline-flex flex-none sub">
          <span
            >La contraseña de inicio de sesión se utiliza para iniciar sesión en
            tu cuenta.</span
          >
          <label class="flex-grow-1"></label>
          <div class="inline-flex flex-none mr-8">
            <button @click="changePassword()" class="btn btn-primary">
              Cambiar
            </button>
          </div>
        </div>
      </div>
      <!-- TODO -->
      <!-- Para el proximo MVP se agregará el modulo de las preguntas de seguridad -->
      <!-- <br>
			<br> -->
      <!-- <div class="flex flex-column">
				<div class="flex">
					<h6>Pregunta de Seguridad</h6>
				</div>
				<div class="inline-flex flex-none sub">
					<h8>La contraseña de inicio de sesión se utiliza para iniciar sesión en tu cuenta.</h8>
					<label class="flex-grow-1"></label>
					<div class="inline-flex flex-none mr-8">
						<button @click="securityQuestions()" class="btn btn-primary">
							Activar
						</button>
					</div>
				</div>
			</div> -->
    </template>
  </card>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      condition: true,
    };
  },
  mounted() {},
  beforeUnmount() {},
  created() {},
  methods: {
    // ...mapMutations("ticketoffice", [
    //   "setActiveClient",]),
    changePassword() {
      this.$emit("changePassword", true);
    },
    // securityQuestions() {
    //   this.$emit("securityQuestions", true);
  },
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.security {
  border: 0.5px solid rgba(0, 0, 0, 0.38);
  border-radius: 10px;
}
.btn {
  background-color: transparent;
  color: #424141;
  border: 0.5px solid #c4c2c2;
  border-radius: 20px;
  margin-left: 15%;
  width: 80px;
  height: 20px;
}

.btn1 {
  background-color: transparent;
  color: #424141;
  border: 0.5px solid #474444;
  border-radius: 5px;
  margin-left: 15%;
  width: 80px;
  height: 20px;
}

.data {
  margin-left: 30%;
}

.btn:hover {
  cursor: pointer;
}
@media screen and (max-width: 820px) {
  .data {
    margin-left: 5%;
  }
}
</style>

<template>
  <div class="profile-menu-bar">
    <user-avatar class="hidden md:block" />
    <Button
      class="block md:hidden"
      type="button"
      icon="pi pi-bars"
      @click="toggleMenu"
      style="width: auto"
    />
    <Menu
      ref="menu"
      :model="items"
      :popup="false"
      v-if="$windowSize.width > 770"
    />
    <Menu
      ref="menu"
      :model="items"
      :popup="true"
      v-if="$windowSize.width < 770"
      class="popup-menu"
    />
  </div>
</template>
<script>
import Menu from "primevue/menu";
import UserAvatar from "@/components/profile/UserAvatar.vue";
export default {
  components: { Menu, UserAvatar },
  data() {
    return {
      popup_mode: false,
      items: [
        {
          label: "Datos Personales",
          icon: "pi pi-user",
          class: "active",
          command: (event) => {
            this.items.forEach((e) => {
              e.class = "";
            });
            event.item.class = "active";
            this.$parent.selected_option = "general";
          },
        },
        {
          label: "Seguridad",
          icon: "pi pi-key",
          command: (event) => {
            this.items.forEach((e) => {
              e.class = "";
            });
            event.item.class = "active";
            this.$parent.selected_option = "seguridad";
          },
        },
        {
          label: "Datos Bancarios",
          icon: "pi pi-briefcase",
          command: (event) => {
            this.items.forEach((e) => {
              e.class = "";
            });
            event.item.class = "active";
            this.$parent.selected_option = "bank";
          },
        },
        /*{
          label: "Preferencias",
          icon: "pi pi-cog",
          command: (event) => {
            this.items.forEach((e) => {
              e.class = "";
            });
            event.item.class = "active";
            this.$parent.selected_option = "preferencias";
          },
        },
        {
          label: "Actividad de la Cuenta",
          icon: "pi pi-history",
          command: (event) => {
            this.items.forEach((e) => {
              e.class = "";
            });
            event.item.class = "active";
            this.$parent.selected_option = "actividad";
          },
        },
        {
          label: "Verificación",
          icon: "pi pi-check-circle",
          command: (event) => {
            this.items.forEach((e) => {
              e.class = "";
            });
            event.item.class = "active";
            this.$parent.selected_option = "verificacion";
          },
        },*/
      ],
    };
  },
  mounted() {},
  beforeUnmount() {},
  created() {},
  methods: {
    toggleMenu(event) {
      this.$refs.menu.toggle(event);
      setTimeout(() => {
        let menu = document.getElementsByClassName("popup-menu");
        if (menu.length) {
          menu[0].style.visibility = "visible";
          menu[0].style.display = "block";
          menu[0].style.top = "130px";
        }
      }, 100);
    },
  },
  computed: {},
  watch: {},
};
</script>
<style lang="scss" scoped>
.profile-menu-bar {
  position: fixed;
  left: 15px;
  z-index: 100;
}
.profile-menu-bar :deep(.p-menu) {
  width: 208px !important;
  min-width: 208px !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}
.profile-menu-bar :deep(.p-menuitem.active) {
  background: #e6e6e6;
  font-weight: 600;
}
@media only screen and (min-width: 770px) and (max-height: 500px) {
  .profile-menu-bar {
    position: relative;
  }
}
</style>
<style>
.popup-menu {
  position: fixed !important;
  top: 0px;
}
</style>
